import { useState } from 'react';
import { NavLink } from "react-router-dom";
import {
    MDBNavbarBrand,
    MDBCollapse,
    MDBNavbar,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBContainer,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBNavbarLink,
    MDBCol,
    MDBFooter,
    MDBIcon,
    MDBRow,
    MDBBtn,
    MDBCard,
    MDBCardFooter,
    MDBCardBody,
    MDBCardText,
    MDBCardTitle,
    MDBCarousel,
    MDBCarouselItem,
    MDBCarouselCaption,
    MDBBadge,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBPopover,
    MDBPopoverBody,
    MDBPopoverHeader,
    MDBTooltip,
} from 'mdb-react-ui-kit';
import './App.css';

function App() {
    const [openBasic, setOpenBasic] = useState(false);
    return (
        <MDBContainer fluid>
            <header>
                {/* Main Navigation */}
                <MDBNavbar expand='lg' className='fixed-top'>
                    {/* Container wrapper */}
                    <MDBContainer sm>

                        <MDBNavbarBrand href='#'>
                            AVision World
                        </MDBNavbarBrand>

                        <MDBCollapse navbar open={openBasic}>
                            <MDBNavbarNav right className='mb-2 mb-lg-0'>
                                <MDBNavbarItem>
                                    <MDBNavbarLink>
                                        <nav id="applications">
                                            <NavLink onClick={() => window.location.replace("/#product")} to='/#product'>
                                                Product Overview
                                            </NavLink>
                                        </nav>
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBDropdown>
                                        <nav id='categories'>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                                AI Features
                                            </MDBDropdownToggle>
                                        </nav>
                                        <MDBDropdownMenu>
                                            <NavLink to="/categories">
                                                <MDBDropdownItem link onClick={() => window.location.replace("/#features")} to='/#features'>AI Forecasting Engine</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={() => window.location.replace("/#features")} to='/#features'>AI Recommendation Engine</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={() => window.location.replace("/#features")} to='/#features'>AI Classification</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={() => window.location.replace("/#features")} to='/#features'>AI Detection</MDBDropdownItem>
                                            </NavLink>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBDropdown>
                                        <nav id='categories'>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                                Pricing
                                            </MDBDropdownToggle>
                                        </nav>
                                        <MDBDropdownMenu>
                                            <NavLink onClick={() => window.location.replace("/#pricing")} to='/#pricing'>
                                                <MDBDropdownItem link>Pricing Guide</MDBDropdownItem>
                                            </NavLink>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <MDBDropdown>
                                        <nav id='categories'>
                                            <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                                                About Us
                                            </MDBDropdownToggle>
                                        </nav>

                                        <MDBDropdownMenu>
                                            <NavLink to="/categories">
                                                <MDBDropdownItem onClick={() => window.location.replace("/#section1")} to='/#section1' link>About Us</MDBDropdownItem>
                                                <MDBDropdownItem onClick={() => window.location.replace("/#section1")} to='/#section1' link>Contact Us</MDBDropdownItem>
                                                <MDBDropdownItem onClick={() => window.location.replace("/#section1")} to='/#section1' link>FAQ</MDBDropdownItem>
                                            </NavLink>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                        </MDBCollapse>

                        <MDBNavbarNav className='d-flex flex-row' right fullWidth={false}>
                            {/* <Notifications />
                            <LanguageSelect />
                            <Avatar /> */}
                            <MDBBtn tag="a" outline size="lg">
                                <NavLink to='https://app.avision.world' target="_blank" rel="noopener noreferrer">
                                    Client Portal
                                </NavLink>
                            </MDBBtn>
                        </MDBNavbarNav>
                    </MDBContainer>
                </MDBNavbar>


            </header>


            <div
                className='p-5 text-center bg-image'
                style={{ backgroundImage: "url('/images/header.jpeg')", height: '100vh', padding: '0px' }}
            >
                <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                        <div className='text-white'>
                            <h1 className='mb-3'>Welcome to AVision.World</h1>
                            <h4 className='mb-3'>Transform Data into Actionable Insights with AI-Powered Analytics</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div id="platform" className='p-1 mb-4'>
                {/* <Helmet>
                    <title>AVision Software: Realize Value with AI</title>
                    <meta name="description" content="AVision Software: Realize Value with AI" />
                    <meta name="keywords" content="AVision Software: Realize Value with AI" />
                    <meta name="author" content="AVision" />
                    <meta property="og:title" content="AVision Software: Realize Value with AI" />
                    <meta property="og:description" content="AVision Software: Realize Value with AI" />
                    <meta property="og:image" content="https://example.com/image.jpg" />
                    <meta property="og:url" content="https://example.com/my-page" />
                    <meta name="twitter:title" content="AVision Software: Realize Value with AI" />
                    <meta name="twitter:description" content="AVision Software: Realize Value with AI" />
                    <meta name="twitter:image" content="https://example.com/image.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet> */}
                <MDBRow className='d-flex justify-content-center'>
                    <MDBCol size='md-8'>
                        <MDBCard>
                            <MDBCardBody id='product'>
                                <MDBCardTitle>
                                    <h6>Our AI Analytics Platform offers a comprehensive solution for businesses looking to leverage data-driven insights across industries such as Real Estate, Hospitality, Healthcare, Media & Advertising, and Retail. The platform is designed to analyze data, video, images, and audio, providing a holistic approach to understanding trends, optimizing performance, and driving growth.</h6>
                                </MDBCardTitle>
                                <MDBCardText className="header-text-align font-color">
                                    <hr className="hr" />
                                    <h6>Key Features:</h6>
                                    <h6>Multi-Modal Analytics: Analyze structured data alongside video, image, and audio for more nuanced insights.</h6>
                                    <h6>Industry-Specific Dashboards: Tailored to meet the unique needs of sectors like real estate, healthcare, and retail, and more with pre-built dashboards and analytics tailored for each industry.</h6>
                                    <h6>Department-Focused Insights: Custom dashboards for Marketing, Sales, Finance, HR, and Executive Leadership ensure each department gets the data they need, presented in a meaningful way.</h6>
                                    <h6>Predictive Analytics: Use AI to forecast trends, customer behavior, and business performance, enabling proactive decision-making.</h6>
                                    <h6>Automation & Optimization: Enhance efficiency through automated processes and AI-driven recommendations, whether for marketing campaigns, sales strategies, or operational workflows.</h6>
                                    <h6>Real-Time Monitoring: Stay updated with real-time alerts and anomaly detection, ensuring you can respond quickly to changes in performance or risk factors.</h6>
                                    <h6>Customizable Reports: Generate detailed reports with flexible export options and scheduled deliveries, ensuring stakeholders receive critical insights when needed.</h6>
                                    <h6>Integration & Interoperability: Connect seamlessly with existing tools like CRM, ERP, and other data sources for a unified view of business performance.</h6>
                                    <hr className="hr" />
                                </MDBCardText>
                                < MDBCardTitle>
                                    <h6>Our AI Models provides an array of capabilities, these encompass broad categories:</h6>
                                </MDBCardTitle>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCarousel showIndicators showControls fade>
                            <MDBCarouselItem itemId={1}>
                                <img src='/images/classification.jpg' className='d-block w-100 h-100' alt='...' />
                                <MDBCarouselCaption>
                                    <h6>Classification</h6>
                                    <p>Classification is a type of supervised learning where the goal is to predict the categorical label of a given input based on its features. In classification tasks, the output variable is discrete, meaning it falls into one of several predefined categories or classes.</p>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>

                            <MDBCarouselItem itemId={2}>
                                <img src='/images/detection.jpg' className='d-block w-100 h-100' alt='...' />
                                <MDBCarouselCaption>
                                    <h6>AI Detection</h6>
                                    <p>Detection, often referred to as object detection in the context of computer vision, is the task of identifying and locating objects within an image or video. Unlike classification, which assigns a single label to an entire image, detection involves both recognizing objects and determining their positions within the image.</p>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>

                            <MDBCarouselItem itemId={3}>
                                <img src='/images/recommendation.webp' className='d-block w-100  h-100' alt='...' />
                                <MDBCarouselCaption>
                                    <h6>AI Recommendation</h6>
                                    <p>Prediction is the process of using historical data and statistical or machine learning models to estimate future outcomes or trends. It involves making informed guesses about what will happen next based on patterns observed in past data.</p>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>

                            <MDBCarouselItem itemId={3}>
                                <img src='/images/forecasting.jpeg' className='d-block w-100  h-100' alt='...' />
                                <MDBCarouselCaption className='carousel-decision'>
                                    <div className="bg-text">
                                        <h6>Forecasting</h6>
                                        <p>Decision capabilities include everything from anomaly detection to automatic content personalization to automated content moderation for text, image, and video applications. Machine learning and advanced analytics are good examples of this domain.</p>
                                    </div>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                        </MDBCarousel>
                        <MDBCard id='features'>
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-1">AI-Powered Solutions Across Departments</p></MDBCardTitle>
                                <hr className="hr" />
                                <MDBCardText className="header-text-align font-color">
                                    <h6>Marketing: Improve campaign performance with AI-driven predictions, audience segmentation, and sentiment analysis.</h6>
                                    <h6>Sales: Drive conversions with lead scoring, revenue forecasting, and opportunity prioritization.</h6>
                                    <h6>Finance: Optimize budgeting, manage risks, and detect anomalies with predictive finance models.</h6>

                                    <h6>HR: Enhance talent management with attrition prediction, recruitment analytics, and performance tracking.</h6>
                                    <h6>Customer Support: Boost service quality with AI-powered ticketing, sentiment analysis, and chatbots.</h6>
                                    <h6>Product Development: Guide innovation with feature usage analysis, release impact forecasting, and bug prioritization.</h6>
                                    <hr className="hr" />
                                    <h6>Our platform’s user-friendly interface ensures that everyone, from data analysts to executives, can easily access the insights they need. By integrating AI at every level, we transform raw data into actionable strategies, helping businesses achieve measurable results.</h6>
                                    <h6>Experience the future of analytics with a platform that doesn’t just track metrics but actively guides your next steps, making it an indispensable tool for data-driven decision-making.</h6>
                                </MDBCardText>

                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>

            <div id='pricing' className='p-1 mb-4'>
                <MDBRow className='d-flex justify-content-center'>
                    <MDBCol size='md-8'>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle><p className="header-1">Pricing Guide</p></MDBCardTitle>
                                <hr className="hr" />
                                <MDBTable align='middle'>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope='col'><p className='fw-bold mb-1'>Features</p></th>
                                            <th scope='col'><p className='fw-bold mb-1'><MDBBadge color='success' pill>Standard</MDBBadge></p></th>
                                            <th scope='col'><p className='fw-bold mb-1'><MDBBadge color='info' pill>Enterprise</MDBBadge></p></th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <p className='fw-bold mb-1'><MDBBadge color='success' pill>
                                                        Core analytics, Basic dashboard, Limited Data Sources
                                                    </MDBBadge></p>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="check" />
                                            </td>
                                            <td><MDBIcon fas icon="times" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <p className='fw-bold mb-1'><MDBBadge color='success' pill>
                                                        Advanced AI models, Industry Templates
                                                    </MDBBadge></p>

                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="check" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <MDBBadge color='primary' pill>
                                                        AI In-App Copilot
                                                    </MDBBadge>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="check" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <MDBBadge color='primary' pill>
                                                        AI Chatbot for Web
                                                    </MDBBadge>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="check" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <MDBBadge color='primary' pill>
                                                        Unlimited Data, Premium Integrations, Priority Support
                                                    </MDBBadge>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="times" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <MDBBadge color='info' pill>
                                                        MS Teams Custom Dashboard
                                                    </MDBBadge>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="times" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <MDBBadge color='info' pill>
                                                        MS Teams AI Copilot
                                                    </MDBBadge>
                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="times" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <MDBBadge color='info' pill>
                                                        Fully customizable, Dedicated Infrastructure
                                                    </MDBBadge>

                                                </div>
                                            </td>
                                            <td>
                                                <MDBIcon fas icon="times" />
                                            </td>
                                            <td><MDBIcon fas icon="check" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <MDBBadge color='dark' pill>Price per month (**excludes setup and implementation fees)</MDBBadge> 
                                                </div>
                                            </td>
                                            <td>$999</td>
                                            <td>$1,999</td>
                                        </tr>
                                    </MDBTableBody>

                                </MDBTable>
                                <MDBCardFooter className="footer-align">
                                    <NavLink to='https://outlook.office.com/bookwithme/user/fff8cf66d34b4a059a366baa2e6cfa5d@avisionsoftware.com?anonymous&ep=plink' target="_blank" rel="noopener noreferrer" className='me-4 text-reset'>
                                        <MDBBtn tag="a" outline size="lg" >
                                            <MDBIcon fab icon="microsoft" /> Register Now
                                        </MDBBtn>
                                    </NavLink>
                                </MDBCardFooter>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>
            <div id='about' className='p-1 mb-4'>
                <MDBRow className='d-flex justify-content-center'>
                    <MDBCol size='md-8'>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle>
                                    <p className="header-1">About Us</p>
                                </MDBCardTitle>
                                <MDBCardText className="header-text-align font-color">
                                    <hr className="hr" />
                                    <h6>We're at the forefront of the AI revolution, committed to developing state-of-the-art software solutions that redefine efficiency, creativity, and decision-making.
                                        Our technology is designed to enhance human potential and empower organizations across various industries. </h6>
                                    <h6>Our approach is grounded in ethical principles, ensuring that our advancements contribute positively to society and the environment.</h6>
                                    <h6>Our team combines deep expertise in AI, data analytics, and industry knowledge across key sectors like Real Estate, Healthcare, and Retail. With experience in building scalable solutions, we excel at turning complex data into actionable insights. Our unique blend of technical skills, domain expertise, and a proven track record of delivering innovative products positions us perfectly to create a platform that drives real impact and growth for our clients. We’re passionate about pushing the boundaries of AI to solve critical business challenges and deliver value at scale.</h6>
                                    <hr className="hr" />
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>
            <MDBFooter className='text-center text-lg-start text-muted'>
                <section className=''>
                    <MDBContainer className='text-center text-md-start mt-5'>
                        <MDBRow className='mt-3'>
                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>
                                    <MDBIcon icon="gem" className="me-3" />
                                    AVision.World
                                </h6>
                                <p>
                                    AVision World is an Advanced AI Analytics Platform, focusing on Data, Audio, Image and Video Analytics.
                                </p>
                            </MDBCol>

                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>Features</h6>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Data Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Video Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Image Analytics
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        AI Audio Analytics
                                    </a>
                                </p>
                            </MDBCol>

                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>AI Features</h6>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        Forecasting & Prediction
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        Recommendation Engine
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        Classification
                                    </a>
                                </p>
                                <p>
                                    <a href='#!' className='text-reset'>
                                        Detection
                                    </a>
                                </p>
                            </MDBCol>

                            <MDBCol md="3" lg="2" xl="3" className='mx-auto mb-md-0 mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4 header-2'>Contact</h6>
                                <p>
                                    <MDBIcon icon="home" className="me-3" />Dubai , United Arab Emirates
                                </p>
                                <p>
                                    <MDBIcon icon="envelope" className="me-3" />register@avision.world
                                </p>
                                <p>
                                    <MDBIcon icon="phone" className="me-3" />+971 58 552 2636
                                </p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </section>
                <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                    <div className='me-5 d-none d-lg-block'>
                        <span className="header-2">Get connected with us on social networks:</span>
                    </div>

                    <div className="header-2">
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="facebook-f" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="twitter" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="google" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="instagram" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="linkedin" />
                        </a>
                        <a href='' className='me-4 text-reset'>
                            <MDBIcon fab icon="github" />
                        </a>
                    </div>
                </section>
                <div className='text-center p-4 header-2' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                    © 2024 Copyright:
                    <a className='text-reset fw-bold' href='http://www.avisionsoftware.com/'>
                        AVision.World
                    </a>
                </div>
            </MDBFooter>
        </MDBContainer>

    );
}

export default App;
